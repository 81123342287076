<template>
  <div style="width: 100%">
    <div class="back-img">
      <div style="font-size: 50px; color: #fff">TECHINICAL COMMITTEE</div>
    </div>

    <div class="text-zone">
      <el-row>
        <el-col :md="8" :sm="12" v-for="colData in data">
          <div class="info-card" v-for="item in colData">
            <div class="title">{{item.title}}</div>
            <a>{{item.name}}</a>            
            <div>{{item.affi}}</div>

          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Technical",
  data(){
    return{
      data:[
          [
            {
              title:'General Chair',
              name:'Jianfeng Zhao',
              affi:'Southeast University'
            },
            {
              title:'',
              name:'Chongqing Kang',
              affi:'Tsinghua University'
            },
            {
              title:'Vice Chair',
              name:'Jinghan He',
              affi:'Beijing Jiaotong University'
            },
            {
              title:'',
              name:'Kaigui Xie',
              affi:'Chongqing University'
            },
            {
              title:'',
              name:'Xuzhu Dong',
              affi:'Wuhan University'
            },
            {
              title:'',
              name:'Zhiwei Wang',
              affi:'State Grid Jiangsu Electric Power Co., Ltd.'
            },
            {
              title:'',
              name:'Wencong Su',
              affi:'University of Michigan-Dearborn'
            },
            {
              title:'',
              name:'Qi Huang',
              affi:'University of Electronic Science and Technology'
            },
            {
              title:'',
              name:'Dong Liu',
              affi:'Shanghai Jiaotong University'
            },
            {
              title:'',
              name:'Ming Ni',
              affi:'NARI Technology Co. Ltd'
            },
            {
              title:'',
              name:'Qixin Chen',
              affi:'Tsinghua University'
            },
            {
              title:'',
              name:'Nian Liu',
              affi:'North China Electric Power University'
            },
            {
              title:'',
              name:'Qiuwei Wu',
              affi:'Technical University of Denmark'
            },
          ],
          [
            {
              title:'',
              name:'Tianshu Bi',
              affi:'North China Electric Power University'
            },
            {
              title:'',
              name:'Fangxing Li',
              affi:'University of Tennessee'
            },
            {
              title:'',
              name:'Chuangxin Guo',
              affi:'Zhejiang University'
            },
            {
              title:'',
              name:'Hongjie Jia',
              affi:'Tianjin University'
            },
            {
              title:'',
              name:'Weisheng Wang',
              affi:'Hunan University'
            },
            {
              title:'',
              name:'Zhengyou He',
              affi:'Southwest Jiaotong University'
            },
            {
              title:'',
              name:'Xiaorong Xie',
              affi:'Tsinghua University'
            },
            {
              title:'',
              name:'Huanhai Xin',
              affi:'Zhejiang University'
            },
            {
              title:'',
              name:'Yong Li',
              affi:'Hunan University'
            },
            {
              title:'',
              name:'Yi Ding',
              affi:'Zhejiang University'
            },
            {
              title:'',
              name:'Wenhu Tang',
              affi:'South China University of Technology'
            },
            {
              title:'',
              name:'Lei Wu',
              affi:'Stevens Institute of Technology'
            },
            {
              title:'',
              name:'Zhaolong Sun',
              affi:'Naval University of Engineering'
            },
            {
              title:'',
              name:'Zuyi Li',
              affi:'Illinois Institute of Technology'
            },
          ],
          [
            
            {
              title:'Members',
              name:'Feng Wu',
              affi:'Hohai University'
            },
            {
              title:'',
              name:'Pertti Järventausta',
              affi:'Tampere University of Technology'
            },
            {
              title:'',
              name:'Ettore Bompard',
              affi:'Politecnico di Torino'
            },
            {
              title:'',
              name:'Pierluigi Siano',
              affi:'University of Salerno'
            },
            {
              title:'',
              name:'Kang Li',
              affi:'University of Nottingham'
            },
            {
              title:'',
              name:'C.Y. Chung',
              affi:'University of Saskatchewan'
            },
            {
              title:'',
              name:'Zhuoran Zhang',
              affi:'Nanjing University of Aeronautics and Astronautics'
            },
            {
              title:'',
              name:'Guangtong Ma',
              affi:'Southwest Jiaotong University'
            },
            {
              title:'',
              name:'Yixiang Shi',
              affi:'Tsinghua University'
            },
            {
              title:'',
              name:'Xinjing Zhang',
              affi:'Institute of Engineering Thermophysics'
            },
            {
              title:'',
              name:'Tao Wang',
              affi:'Zhejiang University'
            },
            {
              title:'',
              name:'Yong Fu',
              affi:'Mississippi State University'
            },
            {
              title:'',
              name:'Hua Ye',
              affi:'Shandong University'
            },
            {
              title:'',
              name:'Zewen Li',
              affi:'Changsha University of Science and Technology'
            },
          ]
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/committees";
</style>
